import { Popover, Skeleton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import { ERROR_CODE } from "constants/constants";
import { EVENT_TYPES } from "constants/mixpanel";
import dayjs from "dayjs";
import useUser from "hooks/userHooks";
import {
  ESCALATION_FOLLOW_UP_TAGS,
  INBOX_DATE_FILTER_OPTIONS,
  INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS,
  INBOX_ESCALATION_TYPE,
  INBOX_SORT_MAPPER,
  INBOX_TABS,
  SORT_BY_OPTIONS,
} from "modules/Retention/constants/constants";
import {
  fetchEmployee,
  fetchEmployeeHistory,
  fetchEscalations,
  fetchInboxSummary,
} from "modules/Retention/services/retention";
import { renderEmploymentStatus } from "modules/Retention/utils/common";
import { renderFollowupSummary } from "modules/Retention/utils/followup";
import { renderSentimentBadgeWithScore } from "modules/Retention/utils/sentiment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { mixpanelTrackEvent } from "services/mixpanel";
import { Loader } from "subframe";
import { CustomBadge } from "subframe/components/CustomBadge";
import { CustomStatCard } from "subframe/components/CustomStatCard";
import { InboxHeader } from "subframe/components/InboxHeader";
import { InboxRow } from "subframe/components/InboxRow";
import { PageHeader } from "subframe/components/PageHeader";
import { Select } from "subframe/components/Select";
import { TabsPills } from "subframe/components/TabsPills";
import { isEmpty as isArrayEmpty, sortArrayObjects } from "utils/array";
import { getLastNDaysFromToday, getRelativeDate } from "utils/date";
import { subscribe, unsubscribe } from "utils/event";
import { isNumber } from "utils/number";
import { isEmpty as isObjectEmpty } from "utils/object";
import { getFullName, isEmpty as isStringEmpty } from "utils/string";
import BranchWithMetadata from "../BranchWithMetadata/BranchWithMetadata";
import EmployeeProfileSlideout from "../EmployeeProfileSlideout";
import EmptyState from "../EmptyState";
import SupervisorWithMetadata from "../SupervisorWithMetadata/SupervisorWithMetadata";

function Inbox() {
  const defaultSort = SORT_BY_OPTIONS["Oldest to Newest"];
  const defaultInboxDateFilter = INBOX_DATE_FILTER_OPTIONS["Last 7 days"];
  const defaultInboxEmployeeStatusFilter =
    INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS["Active"];

  const navigate = useNavigate();

  const [inboxDateFilter, setInboxDateFilter] = useState(null);
  const [inboxEmployeeStatusFilter, setInboxEmployeeStatusFilter] = useState({
    employeeStatus: defaultInboxEmployeeStatusFilter.value,
  });
  const [atRisks, setAtRisks] = useState({});
  const [resolved, setResolved] = useState([]);
  const [employee, setEmployee] = useState({});
  const [dismissed, setDismissed] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [inboxSummary, setInboxSummary] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState("At-risk");
  const [employeeHistory, setEmployeeHistory] = useState([]);
  const [selectedSort, setSelectedSort] = useState(defaultSort);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [selectedEscalationId, setSelectedEscalationId] = useState();
  const [isLoadingAtRisks, setIsLoadingAtRisks] = useState(false);
  const [isLoadingResolved, setIsLoadingResolved] = useState(false);
  const [isFetchingEmployeeHistory, setIsFetchingEmployeeHistory] =
    useState(false);
  const [isFetchingEmployee, setIsFetchingEmployee] = useState(false);
  const [isLoadingDismissed, setIsLoadingDismissed] = useState(false);
  const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [selectedInboxDateFilter, setSelectedInboxDateFilter] = useState(
    defaultInboxDateFilter.label
  );
  const [
    selectedInboxEmployeeStatusFilter,
    setSelectedInboxEmployeeStatusFilter,
  ] = useState(defaultInboxEmployeeStatusFilter.label);
  const [isProfileSlideoutOpen, setIsProfileSlideoutOpen] = useState(false);
  const [isInboxSummaryLoading, setIsInboxSummaryLoading] = useState(false);

  const [followUpNoteAnchorEl, setFollowUpNoteAnchorEl] = useState(null);
  const [anchoredFollowUpNote, setAnchoredFollowUpNote] = useState();
  const escalationListRef = useRef(null);

  // Infinite scrollnig states
  const loadMoreEscalationObservers = useRef({
    overdue: null,
    new: null,
    inProgress: null,
    resolved: null,
    dismissed: null,
  });
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [pageValues, setPageValues] = useState({
    [INBOX_ESCALATION_TYPE.AT_RISK]: 1,
    [INBOX_ESCALATION_TYPE.IN_PROGRESS]: 1,
    [INBOX_ESCALATION_TYPE.RESOLVED]: 1,
    [INBOX_ESCALATION_TYPE.DISMISSED]: 1,
  });
  const [loadingMore, setLoadingMore] = useState({
    [INBOX_ESCALATION_TYPE.AT_RISK]: false,
    [INBOX_ESCALATION_TYPE.IN_PROGRESS]: false,
    [INBOX_ESCALATION_TYPE.RESOLVED]: false,
    [INBOX_ESCALATION_TYPE.DISMISSED]: false,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (!isObjectEmpty(inboxEmployeeStatusFilter) && !isInitialLoad) {
            if (selectedTab === "At-risk") {
              incrementPageValue(selectedTab);
            }

            if (selectedTab === "In Progress") {
              incrementPageValue(selectedTab);
            }

            if (selectedTab === "Resolved") {
              incrementPageValue(selectedTab);
            }

            if (selectedTab === "Dismissed") {
              incrementPageValue(selectedTab);
            }
          }
        }
      },
      { threshold: 1 }
    );

    if (loadMoreEscalationObservers.current) {
      Object.values(loadMoreEscalationObservers.current).forEach((el) => {
        if (el) observer.observe(el);
      });
    }

    return () => {
      if (loadMoreEscalationObservers.current) {
        Object.values(loadMoreEscalationObservers.current).forEach((el) => {
          if (el) observer.unobserve(el);
        });
      }
    };
  }, [loadMoreEscalationObservers, selectedTab]);

  useEffect(() => {
    if (
      !isObjectEmpty(inboxDateFilter) &&
      !isObjectEmpty(inboxEmployeeStatusFilter)
    ) {
      if (
        selectedTab === "At-risk" &&
        inboxSummary?.active_count >
          atRisks?.new?.length + atRisks?.overdue?.length
      ) {
        fetchMoreEscalations({
          ...inboxDateFilter,
          ...inboxEmployeeStatusFilter,
          status: INBOX_ESCALATION_TYPE.AT_RISK,
        });
      }

      if (
        selectedTab === "In Progress" &&
        inboxSummary?.in_progress_count > inProgress.length
      ) {
        fetchMoreEscalations({
          ...inboxDateFilter,
          ...inboxEmployeeStatusFilter,
          status: INBOX_ESCALATION_TYPE.IN_PROGRESS,
        });
      }

      if (
        selectedTab === "Resolved" &&
        inboxSummary?.resolved_count > resolved.length
      ) {
        fetchMoreEscalations({
          ...inboxDateFilter,
          ...inboxEmployeeStatusFilter,
          status: INBOX_ESCALATION_TYPE.RESOLVED,
        });
      }

      if (
        selectedTab === "Dismissed" &&
        inboxSummary?.dismiss_count > dismissed.length
      ) {
        fetchMoreEscalations({
          ...inboxDateFilter,
          ...inboxEmployeeStatusFilter,
          status: INBOX_ESCALATION_TYPE.DISMISSED,
        });
      }
    }
  }, [pageValues]);

  const incrementPageValue = (selectedTab) => {
    const statusKeyMap = {
      "At-risk": INBOX_ESCALATION_TYPE.AT_RISK,
      "In Progress": INBOX_ESCALATION_TYPE.IN_PROGRESS,
      Resolved: INBOX_ESCALATION_TYPE.RESOLVED,
      Dismissed: INBOX_ESCALATION_TYPE.DISMISSED,
    };

    const statusKey = statusKeyMap[selectedTab];
    if (statusKey && !loadingMore[statusKey]) {
      setPageValues((prev) => ({
        ...prev,
        [statusKey]: prev[statusKey] + 1,
      }));
    }
  };

  const fetchMoreEscalations = async (filter = {}) => {
    setLoadingMore((prevState) => ({
      ...prevState,
      [filter.status]: true,
    }));
    const updatedFilter = { ...filter, page: pageValues[filter.status] };
    const response = await fetchEscalationsAsync(updatedFilter);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching At Risks: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      return;
    }

    setLoadingMore((prevState) => ({
      ...prevState,
      [filter.status]: false,
    }));

    if (filter.status === INBOX_ESCALATION_TYPE.AT_RISK) {
      if (
        isArrayEmpty(response?.result?.data?.overdue) &&
        isArrayEmpty(response?.result?.data?.new) &&
        inboxSummary?.active_count >
          atRisks?.new?.length + atRisks?.overdue?.length
      ) {
        setPageValues((prev) => ({
          ...prev,
          [INBOX_ESCALATION_TYPE.AT_RISK]:
            prev[INBOX_ESCALATION_TYPE.AT_RISK] + 1,
        }));
        return;
      }

      setAtRisks((prevAtRisks) => ({
        overdue: updateEscalationList(
          prevAtRisks.overdue ?? [],
          response?.result?.data?.overdue,
          selectedSort,
          "overdue"
        ),
        new: updateEscalationList(
          prevAtRisks.new ?? [],
          response?.result?.data?.new,
          selectedSort,
          "new"
        ),
      }));
    }

    if (filter.status === INBOX_ESCALATION_TYPE.IN_PROGRESS) {
      if (
        isArrayEmpty(response?.result?.data?.in_progress) &&
        inboxSummary?.in_progress_count > inProgress.length
      ) {
        setPageValues((prev) => ({
          ...prev,
          [INBOX_ESCALATION_TYPE.IN_PROGRESS]:
            prev[INBOX_ESCALATION_TYPE.IN_PROGRESS] + 1,
        }));
        return;
      }
      setInProgress((prev) =>
        updateEscalationList(
          prev,
          response?.result?.data?.in_progress,
          selectedSort,
          "inProgress"
        )
      );
    }

    if (filter.status === INBOX_ESCALATION_TYPE.RESOLVED) {
      if (
        isArrayEmpty(response?.result?.data?.escalations) &&
        inboxSummary?.resolved_count > resolved.length
      ) {
        setPageValues((prev) => ({
          ...prev,
          [INBOX_ESCALATION_TYPE.RESOLVED]:
            prev[INBOX_ESCALATION_TYPE.RESOLVED] + 1,
        }));
        return;
      }
      setResolved((prev) =>
        updateEscalationList(
          prev,
          response?.result?.data?.escalations,
          selectedSort,
          "resolved"
        )
      );
    }

    if (filter.status === INBOX_ESCALATION_TYPE.DISMISSED) {
      if (
        isArrayEmpty(response?.result?.data?.escalations) &&
        inboxSummary?.dismiss_count > dismissed.length
      ) {
        setPageValues((prev) => ({
          ...prev,
          [INBOX_ESCALATION_TYPE.DISMISSED]:
            prev[INBOX_ESCALATION_TYPE.DISMISSED] + 1,
        }));
        return;
      }
      setDismissed((prev) =>
        updateEscalationList(
          prev,
          response?.result?.data?.escalations,
          selectedSort,
          "dismissed"
        )
      );
    }
  };

  const updateEscalationList = (prev, newItems, selectedSort, status) => {
    const existingIds = new Set(prev.map((item) => item.escalation_id));

    const uniqueNewItems = sortArrayObjects(
      newItems,
      INBOX_SORT_MAPPER[status][selectedSort.type],
      selectedSort.value
    ).filter((item) => !existingIds.has(item.escalation_id));

    return [...prev, ...uniqueNewItems];
  };

  const handleFollowUpNotePopoverOpen = (event, note) => {
    setFollowUpNoteAnchorEl(event.currentTarget);
    setAnchoredFollowUpNote(note);
  };

  const handleFollowUpNotePopoverClose = () => {
    setFollowUpNoteAnchorEl(null);
    setAnchoredFollowUpNote();
  };

  const { user, isLoading: isLoadingUser } = useUser();

  useEffect(() => {
    subscribe("closeSlideout", handleCloseProfileSlideout);

    mixpanelTrackEvent(EVENT_TYPES.INBOX_PAGE_NAVIGATION);

    return () => {
      unsubscribe("closeSlideout", () => {});
    };
  }, []);

  useEffect(() => {
    if (
      !isObjectEmpty(inboxDateFilter) &&
      !isObjectEmpty(inboxEmployeeStatusFilter)
    ) {
      handleFetchInitialData();
    }
  }, [inboxDateFilter, inboxEmployeeStatusFilter]);

  useEffect(() => {
    setAtRisks({
      overdue: sortArrayObjects(
        atRisks.overdue,
        INBOX_SORT_MAPPER["overdue"][selectedSort.type],
        selectedSort.value
      ),
      new: sortArrayObjects(
        atRisks.new,
        INBOX_SORT_MAPPER["new"][selectedSort.type],
        selectedSort.value
      ),
    });
    setInProgress(
      sortArrayObjects(
        inProgress,
        INBOX_SORT_MAPPER["inProgress"][selectedSort.type],
        selectedSort.value
      )
    );
    setResolved(
      sortArrayObjects(
        resolved,
        INBOX_SORT_MAPPER["resolved"][selectedSort.type],
        selectedSort.value
      )
    );
    setDismissed(
      sortArrayObjects(
        dismissed,
        INBOX_SORT_MAPPER["dismissed"][selectedSort.type],
        selectedSort.value
      )
    );
  }, [selectedSort]);

  useEffect(() => {
    if (isLoadingUser || isObjectEmpty(user)) {
      return;
    }
    const dateFilter =
      user.configuration?.default_date_filter || defaultInboxDateFilter;
    setSelectedInboxDateFilter(dateFilter?.label);
    handleGetLastNDaysFromToday(dateFilter?.value);
  }, [user]);

  const handleFetchInitialData = () => {
    setPageValues({
      [INBOX_ESCALATION_TYPE.AT_RISK]: 1,
      [INBOX_ESCALATION_TYPE.IN_PROGRESS]: 1,
      [INBOX_ESCALATION_TYPE.RESOLVED]: 1,
      [INBOX_ESCALATION_TYPE.DISMISSED]: 1,
    });
    fetchAndSetInboxSummary({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
    });
    handleFetchAtRisks({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      status: INBOX_ESCALATION_TYPE["AT_RISK"],
    });
    handleFetchInProgress({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      status: INBOX_ESCALATION_TYPE["IN_PROGRESS"],
    });
    handleFetchResolved({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      status: INBOX_ESCALATION_TYPE["RESOLVED"],
    });
    handleFetchDismissed({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      status: INBOX_ESCALATION_TYPE["DISMISSED"],
    });
  };

  const handleEscalationFollowUpSubmit = () => {
    handleFetchInitialData();
    handleProfileSlideoutDataFetch(selectedEmployeeId, selectedEscalationId);
  };

  const handleRecognitionPostSubmit = () => {
    handleFetchInitialData();
    handleProfileSlideoutDataFetch(selectedEmployeeId, selectedEscalationId);
  };

  const handleSendReferralSMSSubmit = () => {
    handleFetchInitialData();
    handleProfileSlideoutDataFetch(selectedEmployeeId, selectedEscalationId);
  };

  const fetchAndSetInboxSummary = async (filter = {}) => {
    setIsInboxSummaryLoading(true);
    const response = await fetchAndSetInboxSummaryAsync(filter);

    setIsInboxSummaryLoading(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Inbox Summary: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setInboxSummary({});
    }

    setInboxSummary(response?.result?.escalation_stats);
  };

  const fetchAndSetInboxSummaryAsync = async (filter = {}) => {
    try {
      return await fetchInboxSummary({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        employee_status: filter?.employeeStatus,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to fetch at escalations by API call.
   *
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchEscalationsAsync = async (filter = {}) => {
    try {
      return await fetchEscalations({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        status: filter?.status,
        employee_status: filter?.employeeStatus,
        page: filter?.page ?? 1,
        limit: 20,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to handle the fetching of the at risk employees.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchAtRisks = async (filter = {}) => {
    setIsLoadingAtRisks(true);
    const response = await fetchEscalationsAsync(filter);

    setIsLoadingAtRisks(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching At Risks: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setAtRisks({});

      return;
    }

    setAtRisks({
      overdue: sortArrayObjects(
        response?.result?.data?.overdue,
        INBOX_SORT_MAPPER["overdue"][selectedSort.type],
        selectedSort.value
      ),
      new: sortArrayObjects(
        response?.result?.data?.new,
        INBOX_SORT_MAPPER["new"][selectedSort.type],
        selectedSort.value
      ),
    });
    setIsInitialLoad(false);
  };

  /**
   * Method to handle the fetching of the in progress employees.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchInProgress = async (filter = {}) => {
    setIsLoadingInProgress(true);
    const response = await fetchEscalationsAsync(filter);

    setIsLoadingInProgress(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching In Progress: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setInProgress([]);

      return;
    }

    setInProgress(
      sortArrayObjects(
        response?.result?.data?.in_progress,
        INBOX_SORT_MAPPER["inProgress"][selectedSort.type],
        selectedSort.value
      )
    );
  };

  /**
   * Method to handle the fetching of the resolved employee escalation.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchResolved = async (filter = {}) => {
    setIsLoadingResolved(true);
    const response = await fetchEscalationsAsync(filter);

    setIsLoadingResolved(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Resolved: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setResolved([]);

      return;
    }

    setResolved(
      sortArrayObjects(
        response?.result?.data?.escalations,
        INBOX_SORT_MAPPER["resolved"][selectedSort.type],
        selectedSort.value
      )
    );
  };

  /**
   * Method to handle the fetching of the dismissed employee escalation.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchDismissed = async (filter = {}) => {
    setIsLoadingDismissed(true);
    const response = await fetchEscalationsAsync(filter);

    setIsLoadingDismissed(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Dismissed: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setDismissed([]);

      return;
    }

    setDismissed(
      sortArrayObjects(
        response?.result?.data?.escalations,
        INBOX_SORT_MAPPER["dismissed"][selectedSort.type],
        selectedSort.value
      )
    );
  };

  const handleGetLastNDaysFromToday = (n = 0) => {
    const result = getLastNDaysFromToday(n);
    const startDate =
      dayjs(result?.startDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.startDate)?.format("YYYY-MM-DD");
    const endDate =
      dayjs(result?.endDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.endDate)?.format("YYYY-MM-DD");

    setInboxDateFilter({
      startDate,
      endDate,
    });
  };

  const handleEscalationClick = (employeeId, escalationId) => {
    setSelectedEmployeeId(employeeId);
    setSelectedEscalationId(escalationId);
    openProfileSlideout();
    handleProfileSlideoutDataFetch(employeeId, escalationId);

    mixpanelTrackEvent(EVENT_TYPES.ESCALATED_EMPLOYEE_CLICK, {
      employeeId: employeeId,
    });
  };

  const openProfileSlideout = () => {
    setIsProfileSlideoutOpen(true);
  };

  const closeProfileSlideout = () => {
    setIsProfileSlideoutOpen(false);
  };

  const handleCloseProfileSlideout = () => {
    setSelectedEmployeeId();
    setSelectedEscalationId();
    setEmployee({});
    setEmployeeHistory([]);
    closeProfileSlideout();
  };

  const handleProfileSlideoutDataFetch = (employeeId, escalationId) => {
    handleFetchEmployee(employeeId, escalationId);
    handleFetchEmployeeHistory(employeeId);
  };

  /**
   * Handles fetching of the employee profile.
   *
   * @param {Number} employeeId
   * @param {Number} escalationId
   * @param {Boolean} isSilent
   *
   * @returns {Promise}
   */
  const handleFetchEmployee = async (
    employeeId,
    escalationId,
    isSilent = false
  ) => {
    !isSilent && setIsFetchingEmployee(true);
    const response = await fetchEmployeeAsync(employeeId, escalationId);

    !isSilent && setIsFetchingEmployee(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching employee details: ${response?.error?.message}`
      );
      setEmployee({});
      setIsErrorMessageShown(true);

      return;
    }

    if (!isObjectEmpty(response?.result?.data)) {
      setEmployee(response?.result?.data);
    }
  };

  /**
   * Handles fetching of the employee's history.
   *
   * @param {Number} employeeId
   * @param {Boolean} enableNoteSummary
   * @param {Object|null} injectedData
   * @param {Boolean} isSilent
   *
   * @returns {Promise}
   */
  const handleFetchEmployeeHistory = async (
    employeeId,
    enableNoteSummary = false,
    injectedData = null,
    isSilent = false
  ) => {
    !isSilent && setIsFetchingEmployeeHistory(true);
    const response = await fetchEmployeeHistoryAsync(
      employeeId,
      enableNoteSummary
    );

    !isSilent && setIsFetchingEmployeeHistory(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching employee's history: ${response?.error?.message}`
      );
      setEmployeeHistory([]);
      setIsErrorMessageShown(true);

      return;
    }

    if (!isArrayEmpty(response?.result?.history)) {
      setEmployeeHistory([
        ...response?.result?.history,
        ...(!isObjectEmpty(injectedData) ? [injectedData] : []),
      ]);
    }
  };

  /**
   * Method to fetch the employee profile.
   *
   * @param {Number} employeeId
   * @param escalationId
   */
  const fetchEmployeeAsync = async (employeeId, escalationId) => {
    try {
      return await fetchEmployee({
        employee_id: employeeId,
        escalation_id: escalationId,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to fetch the employee's escalation history.
   *
   * @param {Number} employeeId
   * @param {Boolean} enableNoteSummary
   */
  const fetchEmployeeHistoryAsync = async (
    employeeId,
    enableNoteSummary = false
  ) => {
    try {
      return await fetchEmployeeHistory({
        employee_id: employeeId,
        enable_llm_note_summary: enableNoteSummary ? "1" : "0",
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  const renderStatusTag = (
    status,
    note,
    note_summary = null,
    note_tags = []
  ) => {
    const tag = ESCALATION_FOLLOW_UP_TAGS[status];

    const noteToDisplay = note_summary || note; // Use the note summary if present otherwise the normal one

    return isObjectEmpty(tag) ? (
      "-"
    ) : (
      <>
        <div
          className="group/6c4fd648 flex h-6 items-center gap-1 rounded-full p-4 md:pr-3 md:pl-3"
          style={{ backgroundColor: tag.color.fill }}
          aria-owns={
            Boolean(followUpNoteAnchorEl)
              ? "mouse-over-followup-note-popover"
              : undefined
          }
          aria-haspopup="true"
          onMouseEnter={(event) =>
            handleFollowUpNotePopoverOpen(
              event,
              renderFollowupSummary(noteToDisplay, note_tags, true)
            )
          }
          onMouseLeave={handleFollowUpNotePopoverClose}
        >
          <span
            className="text-label font-label text-brand-800"
            style={{ color: tag.color.text }}
          >
            {tag.label}
          </span>
        </div>
      </>
    );
  };

  const renderAtRisks = () => {
    return (
      <>
        <div className="w-full flex-col items-start flex">
          <InboxHeader id="overdue-section">
            <span className="w-full text-default-font text-subheader font-subheader">
              Overdue
            </span>
          </InboxHeader>
          <div className="w-full flex-col items-start flex max-h-[340px] overflow-auto">
            {isLoadingAtRisks ? (
              <div className="gap-2 w-full flex-col flex">
                <Skeleton variant="rounded" width="100%" height="50px" />
                <Skeleton variant="rounded" width="100%" height="50px" />
                <Skeleton variant="rounded" width="100%" height="50px" />
              </div>
            ) : isArrayEmpty(atRisks?.overdue) ? (
              <div className="h-48 w-full">
                <EmptyState
                  icon={
                    <SubframeCore.Icon
                      className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                      name="FeatherFileCheck"
                    />
                  }
                  primaryText="No overdue escalations yet."
                  secondaryText="Great job! You’re all done following-up with at-risk employees."
                />
              </div>
            ) : (
              <>
                {atRisks.overdue.map((atRisk, index) => {
                  return (
                    <InboxRow
                      onClick={() => {
                        handleEscalationClick(
                          atRisk?.employee_id,
                          atRisk?.escalation_id
                        );
                      }}
                      className={
                        "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                      }
                      key={index}
                    >
                      {renderEmploymentStatus(atRisk.employee_status)}
                      <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                        <span className="text-default-font text-body-bold font-body-bold">
                          {isStringEmpty(
                            getFullName(atRisk.first_name, atRisk.last_name)
                          )
                            ? "-"
                            : getFullName(atRisk.first_name, atRisk.last_name)}
                        </span>
                        <span className="text-subtext-color text-label font-label">
                          {isStringEmpty(atRisk.job_title)
                            ? "-"
                            : atRisk.job_title}
                          ,{" "}
                          {isStringEmpty(atRisk.office_location) ? (
                            "-"
                          ) : (
                            <BranchWithMetadata
                              name={atRisk.office_location}
                              overallSentiment={
                                atRisk.office_location_data
                                  ?.survey_sentiment_data?.["Overall Sentiment"]
                              }
                            />
                          )}
                          , {isStringEmpty(atRisk.region) ? "-" : atRisk.region}
                        </span>
                      </div>
                      <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                        {renderSentimentBadgeWithScore(
                          atRisk.sentiment_score,
                          atRisk.overall_sentiment,
                          atRisk.response_rate,
                          true,
                          "justify-center md:justify-start"
                        )}
                      </div>
                      <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                        <CustomBadge variant="neutral">
                          {isStringEmpty(atRisk.survey_titles[0])
                            ? "-"
                            : atRisk.survey_titles[0]}
                        </CustomBadge>
                      </div>
                      <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                        {isStringEmpty(atRisk.supervisor_info?.id) ? (
                          <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                            <SubframeCore.Icon
                              className="text-default-font text-body-bold font-body-bold"
                              name="FeatherUser"
                            />
                            <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                              {isStringEmpty(atRisk.supervisor)
                                ? "-"
                                : atRisk.supervisor}
                            </span>
                          </div>
                        ) : (
                          <SupervisorWithMetadata
                            name={atRisk.supervisor_info?.name}
                            id={+atRisk.supervisor_info.id}
                          />
                        )}
                      </div>
                      <div className="grow shrink-0 basis-0 whitespace-nowrap md:whitespace-normal w-fit md:w-full h-full flex flex-col justify-center">
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherCalendar"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {isStringEmpty(atRisk.tenure) ? "-" : atRisk.tenure}
                          </span>
                        </div>
                      </div>
                      <div className="grow shrink-0 basis-0 whitespace-nowrap md:whitespace-normal w-fit md:w-full h-full flex-col gap-2 items-start justify-center flex">
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherCalendarDays"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {getRelativeDate(atRisk.escalated_on)}
                          </span>
                        </div>
                      </div>
                    </InboxRow>
                  );
                })}
                {loadingMore[INBOX_ESCALATION_TYPE.AT_RISK] ? (
                  <div className="w-full text-center">
                    <Loader size="small" />
                  </div>
                ) : (
                  <div className="min-h-[24px] w-full"></div>
                )}
              </>
            )}

            <div
              ref={(el) => (loadMoreEscalationObservers.current.overdue = el)}
            ></div>
          </div>
        </div>
        <div className="w-full flex-col items-start flex">
          <InboxHeader>
            <span className="w-full text-default-font text-subheader font-subheader">
              New
            </span>
          </InboxHeader>
          <div className="w-full flex-col items-start flex max-h-[340px] overflow-auto">
            {isLoadingAtRisks ? (
              <div className="gap-2 w-full flex-col flex">
                <Skeleton variant="rounded" width="100%" height="50px" />
                <Skeleton variant="rounded" width="100%" height="50px" />
                <Skeleton variant="rounded" width="100%" height="50px" />
              </div>
            ) : isArrayEmpty(atRisks?.new) ? (
              <div className="h-48 w-full">
                <EmptyState
                  icon={
                    <SubframeCore.Icon
                      className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                      name="FeatherFileCheck"
                    />
                  }
                  primaryText="No new escalations yet."
                  secondaryText="Great job! You’re all done following-up with at-risk employees."
                />
              </div>
            ) : (
              <>
                {atRisks.new.map((atRisk, index) => {
                  return (
                    <InboxRow
                      onClick={() => {
                        handleEscalationClick(
                          atRisk?.employee_id,
                          atRisk?.escalation_id
                        );
                      }}
                      className={
                        "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                      }
                      key={index}
                    >
                      {renderEmploymentStatus(atRisk.employee_status)}
                      <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                        <span className="text-default-font text-body-bold font-body-bold">
                          {isStringEmpty(
                            getFullName(atRisk.first_name, atRisk.last_name)
                          )
                            ? "-"
                            : getFullName(atRisk.first_name, atRisk.last_name)}
                        </span>
                        <span className="text-subtext-color text-label font-label">
                          {isStringEmpty(atRisk.job_title)
                            ? "-"
                            : atRisk.job_title}
                          ,{" "}
                          {isStringEmpty(atRisk.office_location) ? (
                            "-"
                          ) : (
                            <BranchWithMetadata
                              name={atRisk.office_location}
                              overallSentiment={
                                atRisk.office_location_data
                                  ?.survey_sentiment_data?.["Overall Sentiment"]
                              }
                            />
                          )}
                          , {isStringEmpty(atRisk.region) ? "-" : atRisk.region}
                        </span>
                      </div>
                      <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                        {renderSentimentBadgeWithScore(
                          atRisk.sentiment_score,
                          atRisk.overall_sentiment,
                          atRisk.response_rate,
                          true
                        )}
                      </div>
                      <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                        <CustomBadge variant="neutral">
                          {isStringEmpty(atRisk.survey_titles[0])
                            ? "-"
                            : atRisk.survey_titles[0]}
                        </CustomBadge>
                      </div>
                      <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                        {isStringEmpty(atRisk.supervisor_info?.id) ? (
                          <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                            <SubframeCore.Icon
                              className="text-default-font text-body-bold font-body-bold"
                              name="FeatherUser"
                            />
                            <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                              {isStringEmpty(atRisk.supervisor)
                                ? "-"
                                : atRisk.supervisor}
                            </span>
                          </div>
                        ) : (
                          <SupervisorWithMetadata
                            name={atRisk.supervisor_info?.name}
                            id={+atRisk.supervisor_info.id}
                          />
                        )}
                      </div>
                      <div className="grow shrink-0 basis-0 w-full h-full flex flex-col justify-center">
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherCalendar"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {isStringEmpty(atRisk.tenure) ? "-" : atRisk.tenure}
                          </span>
                        </div>
                      </div>
                      <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-2 items-start justify-center flex">
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherCalendarDays"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {getRelativeDate(atRisk.escalated_on)}
                          </span>
                        </div>
                      </div>
                    </InboxRow>
                  );
                })}
                {loadingMore[INBOX_ESCALATION_TYPE.AT_RISK] &&
                atRisks.new?.length > 0 ? (
                  <div className="w-full text-center">
                    <Loader size="small" />
                  </div>
                ) : (
                  <div className="min-h-[24px] w-full"></div>
                )}
              </>
            )}

            <div
              ref={(el) => (loadMoreEscalationObservers.current.new = el)}
            ></div>
          </div>
        </div>
      </>
    );
  };

  const renderResolved = () => {
    return (
      <>
        <div
          id="resolved-section"
          className="w-full flex-col items-start flex flex-1 overflow-auto"
        >
          {isLoadingResolved ? (
            <div className="gap-2 w-full flex-col flex">
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
            </div>
          ) : isArrayEmpty(resolved) ? (
            <EmptyState
              icon={
                <SubframeCore.Icon
                  className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                  name="FeatherFileBox"
                />
              }
              primaryText="No resolved escalations yet."
              secondaryText="Follow-up with at-risk employees."
            />
          ) : (
            <>
              {resolved.map((item) => {
                return (
                  <InboxRow
                    onClick={() => {
                      handleEscalationClick(
                        item?.employee_id,
                        item?.escalation_id
                      );
                    }}
                    className={
                      "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                    }
                  >
                    {renderEmploymentStatus(item.employee_status)}
                    <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                      <span className="text-default-font text-body-bold font-body-bold">
                        {isStringEmpty(
                          getFullName(item.first_name, item.last_name)
                        )
                          ? "-"
                          : getFullName(item.first_name, item.last_name)}
                      </span>
                      <span className="text-subtext-color text-label font-label">
                        {isStringEmpty(item.job_title) ? "-" : item.job_title},{" "}
                        {isStringEmpty(item.office_location) ? (
                          "-"
                        ) : (
                          <BranchWithMetadata
                            name={item.office_location}
                            overallSentiment={
                              item.office_location_data
                                ?.survey_sentiment_data?.["Overall Sentiment"]
                            }
                          />
                        )}
                        , {isStringEmpty(item.region) ? "-" : item.region}
                      </span>
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      {renderSentimentBadgeWithScore(
                        item.sentiment_score,
                        item.overall_sentiment,
                        item.response_rate,
                        true
                      )}
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      <CustomBadge variant="neutral">
                        {isStringEmpty(item.survey_titles[0])
                          ? "-"
                          : item.survey_titles[0]}
                      </CustomBadge>
                    </div>
                    <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                      {isStringEmpty(item.supervisor_info?.id) ? (
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherUser"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {isStringEmpty(item.supervisor)
                              ? "-"
                              : item.supervisor}
                          </span>
                        </div>
                      ) : (
                        <SupervisorWithMetadata
                          name={item.supervisor_info?.name}
                          id={+item.supervisor_info.id}
                        />
                      )}
                    </div>
                    <div className="grow shrink-0 basis-0 w-full h-full flex flex-col justify-center">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendar"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {isStringEmpty(item.tenure) ? "-" : item.tenure}
                        </span>
                      </div>
                    </div>
                    <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-2 items-start justify-center flex">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendarDays"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {getRelativeDate(item.resolved_on)}
                        </span>
                      </div>
                    </div>
                  </InboxRow>
                );
              })}
              {loadingMore[INBOX_ESCALATION_TYPE.RESOLVED] ? (
                <div className="w-full text-center">
                  <Loader size="small" />
                </div>
              ) : (
                <div className="min-h-[16px] w-full"></div>
              )}
            </>
          )}

          <div
            ref={(el) => (loadMoreEscalationObservers.current.resolved = el)}
          ></div>
        </div>
      </>
    );
  };

  const renderDismissed = () => {
    return (
      <>
        <div
          id="dismissed-section"
          className="w-full flex-col items-start flex flex-1 overflow-auto"
        >
          {isLoadingDismissed ? (
            <div className="gap-2 w-full flex-col flex">
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
            </div>
          ) : isArrayEmpty(dismissed) ? (
            <EmptyState
              icon={
                <SubframeCore.Icon
                  className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                  name="FeatherFileBox"
                />
              }
              primaryText="No dismissed escalations yet."
              secondaryText="Follow-up with at-risk employees."
            />
          ) : (
            <>
              {dismissed.map((item) => {
                return (
                  <InboxRow
                    onClick={() => {
                      handleEscalationClick(
                        item?.employee_id,
                        item?.escalation_id
                      );
                    }}
                    className={
                      "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                    }
                  >
                    {renderEmploymentStatus(item.employee_status)}
                    <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                      <span className="text-default-font text-body-bold font-body-bold">
                        {isStringEmpty(
                          getFullName(item.first_name, item.last_name)
                        )
                          ? "-"
                          : getFullName(item.first_name, item.last_name)}
                      </span>
                      <span className="text-subtext-color text-label font-label">
                        {isStringEmpty(item.job_title) ? "-" : item.job_title},{" "}
                        {isStringEmpty(item.office_location) ? (
                          "-"
                        ) : (
                          <BranchWithMetadata
                            name={item.office_location}
                            overallSentiment={
                              item.office_location_data
                                ?.survey_sentiment_data?.["Overall Sentiment"]
                            }
                          />
                        )}
                        , {isStringEmpty(item.region) ? "-" : item.region}
                      </span>
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      {renderSentimentBadgeWithScore(
                        item.sentiment_score,
                        item.overall_sentiment,
                        item.response_rate,
                        true
                      )}
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      <CustomBadge variant="neutral">
                        {isStringEmpty(item.survey_titles[0])
                          ? "-"
                          : item.survey_titles[0]}
                      </CustomBadge>
                    </div>
                    <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                      {isStringEmpty(item.supervisor_info?.id) ? (
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherUser"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {isStringEmpty(item.supervisor)
                              ? "-"
                              : item.supervisor}
                          </span>
                        </div>
                      ) : (
                        <SupervisorWithMetadata
                          name={item.supervisor_info?.name}
                          id={+item.supervisor_info.id}
                        />
                      )}
                    </div>
                    <div className="grow shrink-0 basis-0 w-full h-full flex flex-col justify-center">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendar"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {isStringEmpty(item.tenure) ? "-" : item.tenure}
                        </span>
                      </div>
                    </div>
                    <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-2 items-start justify-center flex">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendarDays"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {getRelativeDate(item.dismissed_on)}
                        </span>
                      </div>
                    </div>
                  </InboxRow>
                );
              })}
              {loadingMore[INBOX_ESCALATION_TYPE.DISMISSED] ? (
                <div className="w-full text-center">
                  <Loader size="small" />
                </div>
              ) : (
                <div className="min-h-[24px] w-full"></div>
              )}
            </>
          )}

          <div
            ref={(el) => (loadMoreEscalationObservers.current.dismissed = el)}
          ></div>
        </div>
      </>
    );
  };

  const renderInProgress = () => {
    return (
      <>
        <div
          id="in-progress-section"
          className="w-full flex-col items-start flex flex-1 overflow-auto"
        >
          {isLoadingInProgress ? (
            <div className="gap-2 w-full flex-col flex">
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
            </div>
          ) : isArrayEmpty(inProgress) ? (
            <EmptyState
              icon={
                <SubframeCore.Icon
                  className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                  name="FeatherFileBox"
                />
              }
              primaryText="No in progress escalations yet."
              secondaryText="Follow-up with at-risk employees."
            />
          ) : (
            <>
              {inProgress.map((item) => {
                return (
                  <InboxRow
                    onClick={() => {
                      handleEscalationClick(
                        item?.employee_id,
                        item?.escalation_id
                      );
                    }}
                    className={
                      "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                    }
                  >
                    {renderEmploymentStatus(item.employee_status)}
                    <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                      <span className="text-default-font text-body-bold font-body-bold">
                        {isStringEmpty(
                          getFullName(item.first_name, item.last_name)
                        )
                          ? "-"
                          : getFullName(item.first_name, item.last_name)}
                      </span>
                      <span className="text-subtext-color text-label font-label">
                        {isStringEmpty(item.job_title) ? "-" : item.job_title},{" "}
                        {isStringEmpty(item.office_location) ? (
                          "-"
                        ) : (
                          <BranchWithMetadata
                            name={item.office_location}
                            overallSentiment={
                              item.office_location_data
                                ?.survey_sentiment_data?.["Overall Sentiment"]
                            }
                          />
                        )}
                        , {isStringEmpty(item.region) ? "-" : item.region}
                      </span>
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      {renderSentimentBadgeWithScore(
                        item.sentiment_score,
                        item.overall_sentiment,
                        item.response_rate,
                        true
                      )}
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      {renderStatusTag(
                        item.escalation_latest_followup_tags?.[0],
                        item.escalation_latest_followup_note,
                        item.escalation_latest_followup_note_summary,
                        item.escalation_latest_followup_note_tags
                      )}
                    </div>
                    <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                      {isStringEmpty(item.supervisor_info?.id) ? (
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherUser"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {isStringEmpty(item.supervisor)
                              ? "-"
                              : item.supervisor}
                          </span>
                        </div>
                      ) : (
                        <SupervisorWithMetadata
                          name={item.supervisor_info?.name}
                          id={+item.supervisor_info.id}
                        />
                      )}
                    </div>
                    <div className="grow shrink-0 basis-0 w-full h-full flex flex-col justify-center">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendar"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {isStringEmpty(item.tenure) ? "-" : item.tenure}
                        </span>
                      </div>
                    </div>
                    <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-2 items-start justify-center flex">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendarDays"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {getRelativeDate(item.in_progress_on)}
                        </span>
                      </div>
                    </div>
                  </InboxRow>
                );
              })}
              {loadingMore[INBOX_ESCALATION_TYPE.IN_PROGRESS] ? (
                <div className="w-full text-center">
                  <Loader size="small" />
                </div>
              ) : (
                <div className="min-h-[24px] w-full"></div>
              )}
            </>
          )}

          <div
            ref={(el) => (loadMoreEscalationObservers.current.inProgress = el)}
          ></div>
        </div>
      </>
    );
  };

  const handleRenderLineItems = () => {
    if (selectedTab === "At-risk") {
      return renderAtRisks();
    }

    if (selectedTab === "In Progress") {
      return renderInProgress();
    }

    if (selectedTab === "Resolved") {
      return renderResolved();
    }

    if (selectedTab === "Dismissed") {
      return renderDismissed();
    }
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  const renderTabPillLoader = (tab = "") => {
    const loadingStates = {
      "At-risk": isLoadingAtRisks,
      "In Progress": isLoadingInProgress,
      Resolved: isLoadingResolved,
      Dismissed: isLoadingDismissed,
    };

    const isLoading = loadingStates[tab];

    return (
      <>
        <span>{tab}</span>
        {isLoading && (
          <Loader
            size="small"
            className={
              selectedTab === tab ? "text-brand-600" : "text-neutral-400"
            }
          />
        )}
      </>
    );
  };

  const handleStatCardClick = (selectedTab) => {
    setSelectedTab(selectedTab);
    setTimeout(() => {
      escalationListRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <>
      <EmployeeProfileSlideout
        employee={employee}
        user={user}
        isLoadingUser={isLoadingUser}
        close={handleCloseProfileSlideout}
        isOpen={isProfileSlideoutOpen}
        isFetchingEmployee={isFetchingEmployee}
        employeeHistory={employeeHistory}
        isFetchingEmployeeHistory={isFetchingEmployeeHistory}
        employeeId={selectedEmployeeId}
        escalationId={selectedEscalationId}
        onEscalationFollowUpSubmit={handleEscalationFollowUpSubmit}
        onRecognitionPostSubmit={handleRecognitionPostSubmit}
        onSendReferralSMSSubmit={handleSendReferralSMSSubmit}
        onToggleNoteSummary={handleFetchEmployeeHistory}
      />
      <div className="w-full h-full flex-col gap-8 p-4 md:p-12 items-start flex bg-default-background overflow-auto">
        <PageHeader>
          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
            <SubframeCore.Icon
              className="text-neutral-400 text-header font-header"
              name="FeatherInbox"
            />
            <span className="grow shrink-0 basis-0 w-full text-default-font text-section-header font-section-header line-clamp-1">
              Inbox
            </span>
          </div>
          <div className="flex grow w-full gap-4 justify-between md:justify-end">
            {isLoadingAtRisks ||
            isLoadingInProgress ||
            isLoadingResolved ||
            isLoadingDismissed ? (
              <Skeleton variant="rounded" width="120px" height="35px" />
            ) : (
              <Select
                className="flex-none w-[calc(50%-1rem)] md:w-auto h-9"
                placeholder="Status"
                size="Small"
                value={selectedInboxEmployeeStatusFilter}
                onValueChange={(label) => {
                  setSelectedInboxEmployeeStatusFilter(label);
                  setInboxEmployeeStatusFilter({
                    employeeStatus:
                      INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS[label].value,
                  });
                }}
              >
                {Object.keys(INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS).map(
                  (key) => (
                    <Select.Item
                      value={INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS[key].label}
                      key={INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS[key].value}
                    />
                  )
                )}
              </Select>
            )}
            {isLoadingAtRisks ||
            isLoadingInProgress ||
            isLoadingResolved ||
            isLoadingDismissed ? (
              <Skeleton variant="rounded" width="120px" height="35px" />
            ) : (
              <Select
                className="flex-none w-[calc(50%-1rem)] md:w-auto h-9"
                placeholder="Last 7 days"
                size="Small"
                value={selectedInboxDateFilter}
                onValueChange={(label) => {
                  setSelectedInboxDateFilter(label);
                  handleGetLastNDaysFromToday(
                    INBOX_DATE_FILTER_OPTIONS[label].value
                  );
                }}
              >
                {Object.keys(INBOX_DATE_FILTER_OPTIONS).map((key) => (
                  <Select.Item
                    value={INBOX_DATE_FILTER_OPTIONS[key].label}
                    key={INBOX_DATE_FILTER_OPTIONS[key].value}
                  />
                ))}
              </Select>
            )}
          </div>
        </PageHeader>
        <div className="w-full gap-4 items-center flex flex-wrap justify-between md:flex-nowrap">
          {isInboxSummaryLoading ? (
            <>
              <Skeleton variant="rounded" width="100%" height="85px" />
              <Skeleton variant="rounded" width="100%" height="85px" />
              <Skeleton variant="rounded" width="100%" height="85px" />
              <Skeleton variant="rounded" width="100%" height="85px" />
            </>
          ) : (
            <>
              <CustomStatCard
                title="At-risk"
                value={
                  !isNumber(inboxSummary?.active_count)
                    ? "-"
                    : inboxSummary?.active_count?.toString()
                }
                variant="at-risk"
                onClick={() => handleStatCardClick("At-risk")}
                className="hover:shadow-md transition-shadow hover:cursor-pointer max-w-[calc(50%-1rem)] md:max-w-none"
              />
              <CustomStatCard
                title="In Progress"
                value={
                  !isNumber(inboxSummary?.in_progress_count)
                    ? "-"
                    : inboxSummary?.in_progress_count?.toString()
                }
                variant="escalated"
                onClick={() => handleStatCardClick("In Progress")}
                className="hover:shadow-md transition-shadow hover:cursor-pointer max-w-[calc(50%-1rem)] md:max-w-none"
              />
              <CustomStatCard
                title="Resolved"
                value={
                  !isNumber(inboxSummary?.resolved_count)
                    ? "-"
                    : inboxSummary?.resolved_count?.toString()
                }
                variant="resolved"
                onClick={() => handleStatCardClick("Resolved")}
                className="hover:shadow-md transition-shadow hover:cursor-pointer max-w-[calc(50%-1rem)] md:max-w-none"
              />
              <CustomStatCard
                title="Dismissed"
                value={
                  !isNumber(inboxSummary?.dismiss_count)
                    ? "-"
                    : inboxSummary?.dismiss_count?.toString()
                }
                onClick={() => handleStatCardClick("Dismissed")}
                className="hover:shadow-md transition-shadow hover:cursor-pointer max-w-[calc(50%-1rem)] md:max-w-none"
              />
            </>
          )}
        </div>
        <div className="w-full justify-between flex-wrap items-start flex gap-4 md:gap-0 flex-col-reverse md:flex-row">
          <TabsPills
            ref={escalationListRef}
            className="w-auto h-auto flex-wrap pt-2"
            style={{ scrollMarginTop: "6rem" }}
          >
            {INBOX_TABS.map((tab, index) => (
              <TabsPills.Item
                active={selectedTab === tab}
                value={tab}
                onClick={() => setSelectedTab(tab)}
                key={index}
              >
                <div className="flex justify-between sm:justify-center items-center sm:items-center gap-1">
                  {renderTabPillLoader(tab)}
                </div>
              </TabsPills.Item>
            ))}
          </TabsPills>
          <div className="gap-2 items-center flex w-full md:w-auto">
            <Select
              className="flex-none h-9 w-full md:w-auto"
              placeholder="Sort by"
              size="Small"
              defaultValue={defaultSort.label}
              onValueChange={(value) => setSelectedSort(SORT_BY_OPTIONS[value])}
            >
              {Object.keys(SORT_BY_OPTIONS).map((key) => {
                return (
                  <Select.Item value={SORT_BY_OPTIONS[key].label} key={key} />
                );
              })}
            </Select>
          </div>
        </div>
        <div id="escalations" className="w-full">
          {handleRenderLineItems()}
        </div>
      </div>
      <Popover
        elevation={1}
        id="mouse-over-followup-note-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={Boolean(followUpNoteAnchorEl)}
        anchorEl={followUpNoteAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleFollowUpNotePopoverClose}
        disableRestoreFocus
      >
        <div className="p-2 max-w-[400px]">
          <span className="text-label font-label">{anchoredFollowUpNote}</span>
        </div>
      </Popover>
      {renderErrorSnackbar()}
    </>
  );
}

export default Inbox;
