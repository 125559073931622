import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";

import { isEmpty as isStringEmpty } from "../../../../utils/string";

import ReferralSignupSuccess from "../../assets/common/images/referral-signup-success.svg";

export default function ReferralIntermediateRedirector() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      const agencyRedirectLink = searchParams.get("redirectUrl");

      if (isStringEmpty(agencyRedirectLink)) {
        return;
      }

      window.location.replace(agencyRedirectLink);
    }, 1500);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <Box
      sx={{
        gap: "80px",
        width: "100%",
        height: "100%",
        display: "flex",
        padding: "24px",
        minHeight: "100dvh",

        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "var(--fontWeight-bold)",
          }}
          gutterBottom
          className="text-brand-700"
        >
          Success!
        </Typography>
        <Typography variant="body1" gutterBottom textAlign="center">
          Your sign up was successful using the referral.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={ReferralSignupSuccess}
          alt="referral-signup-success"
          style={{
            width: "160px",
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "var(--fontWeight-bold)",
          }}
          gutterBottom
          className="text-brand-700"
        >
          Hang Tight!
        </Typography>

        <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
          <CircularProgress size={16} />

          <Typography variant="body1" sx={{ mb: 4 }} textAlign="center">
            Redirecting you to the agency's portal...
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
