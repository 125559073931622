import { Box, Dialog, Skeleton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import HtmlTooltip from "components/HtmlTooltip";
import { ERROR_CODE } from "constants/constants";
import { fetchEmployeeReferrals } from "modules/Referrals/services/referral";
import { RECOGNITION_TYPE } from "modules/Retention/constants/constants";
import { fetchEmployeeRecognitions } from "modules/Retention/services/retention";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "subframe";
import { isEmpty as isArrayEmpty } from "utils/array";
import { isEmpty as isObjectEmpty } from "utils/object";
import Recognitions from "../Recognitions/Recognitions";
import RecognitionPost from "../SendRecognition/SendRecognition";
import SendReferralSMS from "../SendReferralSMS";

const RecognitionsAndReferrals = ({
  user = {},
  employee = {},
  isFetchingUser = false,
  isFetchingEmployee = false,
  handleRecognitionPostSubmit = () => {},
}) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [recognitions, setRecognitions] = useState({});
  const [referralsStat, setReferralsStat] = useState({});
  const [recognitionType, setRecognitionType] = useState(
    RECOGNITION_TYPE.RECEIVED
  );
  const [isRecognitionsDialogOpen, setIsRecognitionsDialogOpen] =
    useState(false);
  const [isSendRecognitionDialogOpen, setIsSendRecognitionDialogOpen] =
    useState(false);
  const [isSendReferralSMSDialogOpen, setIsSendReferralSMSDialogOpen] =
    useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [areRecognitionsLoading, setAreRecognitionsLoading] = useState(false);
  const [isReferralsStatLoading, setIsReferralsStatLoading] = useState(false);

  useEffect(() => {
    handleFetchRecognitions(employee?.profile?.employee_id);
    handleFetchReferralStat(employee?.profile?.phone_number);
  }, [employee]);

  const fetchRecognitionsAsync = async (employeeId) => {
    try {
      return await fetchEmployeeRecognitions({
        employee_id: employeeId,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  const fetchReferralsStatAsync = async (caregiverId) => {
    try {
      return await fetchEmployeeReferrals({
        caregiver_id: caregiverId,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  const handleFetchRecognitions = async (employeeId) => {
    if (employeeId == undefined) {
      return;
    }

    setAreRecognitionsLoading(true);
    const response = await fetchRecognitionsAsync(
      employee?.profile?.employee_id
    );

    setAreRecognitionsLoading(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching recognitions: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);
      setRecognitions({});

      return;
    }

    setRecognitions(response?.result?.data || {});
  };

  const handleFetchReferralStat = async (caregiverId) => {
    if (caregiverId == undefined) {
      return;
    }

    setIsReferralsStatLoading(true);
    const response = await fetchReferralsStatAsync(caregiverId);

    setIsReferralsStatLoading(false);

    if (response?.hasError) {
      setReferralsStat({});

      return;
    }

    setReferralsStat(response);
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <div className="flex w-full flex-col items-start gap-4 rounded border border-solid border-neutral-border bg-default-background pt-4 pr-4 pb-4 pl-4">
        <span className="text-subheader font-subheader text-default-font">
          Recognitions and Referrals
        </span>
        <div className="flex w-full min-w-[320px] flex-col items-center gap-2">
          {isFetchingEmployee || areRecognitionsLoading ? (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Box>
          ) : (
            <div className="flex w-full items-center justify-center gap-2 pt-3 pr-3 pb-3 pl-3">
              <SubframeCore.Icon
                className="text-section-header font-section-header text-neutral-400"
                name="FeatherAward"
              />
              <div className="flex w-full grow shrink-0 basis-0 items-center justify-center gap-2">
                <span className="text-subheader font-subheader text-default-font">
                  {recognitions?.recognitions_received == undefined
                    ? "-"
                    : recognitions?.recognitions_received?.length}
                </span>
                <span className="w-full grow shrink-0 basis-0 text-body-bold font-body-bold text-default-font">
                  recognitions received
                </span>
              </div>
              {!isArrayEmpty(recognitions?.recognitions_received) && (
                <Button
                  variant="neutral-secondary"
                  size="small"
                  icon="FeatherEye"
                  onClick={() => {
                    setRecognitionType(RECOGNITION_TYPE.RECEIVED);
                    setIsRecognitionsDialogOpen(true);
                  }}
                >
                  View
                </Button>
              )}
            </div>
          )}
          {isFetchingEmployee || areRecognitionsLoading ? (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Box>
          ) : (
            <div className="flex w-full items-start gap-2 pt-3 pr-3 pb-3 pl-3">
              <SubframeCore.Icon
                className="text-section-header font-section-header text-neutral-400"
                name="FeatherGift"
              />
              <div className="flex w-full grow shrink-0 basis-0 items-center gap-2">
                <span className="text-subheader font-subheader text-default-font">
                  {recognitions?.recognitions_sent == undefined
                    ? "-"
                    : recognitions?.recognitions_sent?.length}
                </span>
                <span className="w-full grow shrink-0 basis-0 text-body-bold font-body-bold text-default-font">
                  recognitions sent
                </span>
              </div>
              {!isArrayEmpty(recognitions?.recognitions_sent) && (
                <Button
                  variant="neutral-secondary"
                  size="small"
                  icon="FeatherEye"
                  onClick={() => {
                    setRecognitionType(RECOGNITION_TYPE.PROVIDED);
                    setIsRecognitionsDialogOpen(true);
                  }}
                >
                  View
                </Button>
              )}
            </div>
          )}

          {isFetchingEmployee || isReferralsStatLoading ? (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Box>
          ) : (
            <div className="flex w-full items-center justify-center gap-2 pt-3 pr-3 pb-3 pl-3">
              <SubframeCore.Icon
                className="text-section-header font-section-header text-neutral-400"
                name="FeatherUserPlus"
              />
              <div className="flex w-full grow shrink-0 basis-0 items-center gap-2">
                <span className="text-subheader font-subheader text-default-font">
                  {referralsStat?.total_referral_bonus == undefined
                    ? "-"
                    : `$${referralsStat?.total_referral_bonus}`}
                </span>
                <span className="w-full grow shrink-0 basis-0 text-body-bold font-body-bold text-default-font">
                  referral bonus
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full items-start gap-4 flex-wrap">
          {isFetchingEmployee ? (
            <Skeleton variant="rounded" width="100%" height="35px" />
          ) : (
            <HtmlTooltip
              title={
                user?.configuration?.enable_recognition_post ? (
                  ""
                ) : (
                  <Alert
                    variant="brand"
                    icon="FeatherInfo"
                    title="This feature is not yet enabled for your organization."
                    description="Reach out to Levo if you want to learn more!"
                  />
                )
              }
            >
              <Button
                className="w-full grow shrink-0 basis-0 h-8"
                variant="neutral-secondary"
                size="small"
                icon="FeatherAward"
                onClick={() => setIsSendRecognitionDialogOpen(true)}
                disabled={
                  !user?.configuration?.enable_recognition_post ||
                  isObjectEmpty(employee)
                }
              >
                Send Recognition
              </Button>
            </HtmlTooltip>
          )}

          {isFetchingEmployee || isFetchingUser ? (
            <Skeleton variant="rounded" width="100%" height="35px" />
          ) : (
            <HtmlTooltip
              title={
                user?.configuration?.enable_referral_sms ? (
                  ""
                ) : (
                  <Alert
                    variant="brand"
                    icon="FeatherInfo"
                    title="This feature is not yet enabled for your organization."
                    description="Reach out to Levo if you want to learn more!"
                  />
                )
              }
            >
              <Button
                className="w-full grow shrink-0 basis-0 h-8"
                variant="neutral-secondary"
                size="small"
                icon="FeatherMailPlus"
                onClick={() => setIsSendReferralSMSDialogOpen(true)}
                disabled={
                  isObjectEmpty(user) ||
                  !user?.configuration?.enable_referral_sms ||
                  isObjectEmpty(employee)
                }
              >
                Send Referral SMS
              </Button>
            </HtmlTooltip>
          )}
        </div>
      </div>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={isSendRecognitionDialogOpen}
        onClose={() => setIsSendRecognitionDialogOpen(false)}
      >
        <RecognitionPost
          user={user}
          canMinimize={true}
          isLoadingUser={isFetchingUser}
          closeButtonIcon="FeatherX"
          onSubmit={() => {
            handleFetchRecognitions(employee?.profile?.employee_id);
            handleRecognitionPostSubmit(employee?.profile?.employee_id);
          }}
          employeeId={employee?.profile?.employee_id}
          onMinimize={() => setIsSendRecognitionDialogOpen(false)}
        />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isSendReferralSMSDialogOpen}
        onClose={() => setIsSendReferralSMSDialogOpen(false)}
      >
        <SendReferralSMS
          canMinimize={true}
          closeButtonIcon="FeatherX"
          agencyName={user?.name}
          employeeName={employee?.profile?.first_name}
          employeePhoneNumber={employee?.profile?.phone_number}
          onMinimize={() => setIsSendReferralSMSDialogOpen(false)}
        />
      </Dialog>
      <Dialog
        open={isRecognitionsDialogOpen}
        onClose={() => setIsRecognitionsDialogOpen(false)}
      >
        <Recognitions
          type={recognitionType}
          provided={recognitions?.recognitions_sent}
          received={recognitions?.recognitions_received}
          onClose={() => setIsRecognitionsDialogOpen(false)}
        />
      </Dialog>
      {renderErrorSnackbar()}
    </>
  );
};

export default RecognitionsAndReferrals;
