import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { isNumber } from "utils/number";

const getBarColor = (turnover) => {
  if (!isNumber(turnover)) {
    return "#171717";
  }

  if (turnover <= 20) {
    return "#22c55e"; // Green
  } else if (turnover <= 40) {
    return "#eab308"; // Yellow
  } else {
    return "#ef4444"; // Red
  }
};

const getTextColor = (turnover) => {
  if (!isNumber(turnover)) {
    return "#171717";
  }

  if (turnover <= 20) {
    return "#15803d"; // Green
  } else if (turnover <= 40) {
    return "#a16207"; // Yellow
  } else {
    return "#b91c1c"; // Red
  }
};

const TurnoverChart = ({ branchTurnover, peerTurnover, companyTurnover }) => {
  // Clamp all values to a maximum of 100
  const clampedBranchTurnover = Math.min(branchTurnover, 100);
  const clampedPeerTurnover = Math.min(peerTurnover, 100);
  const clampedCompanyTurnover = Math.min(companyTurnover, 100);

  const data = [
    {
      name: "Branch",
      turnover: clampedBranchTurnover,
    },
  ];

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex w-full justify-between items-center">
        <span className="text-body font-body text-default-font">
          Branch Turnover
        </span>
        <span
          className="text-body-bold font-body-bold text-default-font"
          style={{ color: getTextColor(branchTurnover) }} // Apply the color dynamically
        >
          {isNumber(branchTurnover) ? `${branchTurnover}%` : "-"}
        </span>
      </div>
      <ResponsiveContainer width="100%" height={75}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
        >
          <XAxis
            type="number"
            domain={[0, 100]} // Always set the domain to 100 to keep the chart within bounds
            tick={false}
            tickFormatter={(value) => `${value}%`}
            tickLine={false}
            axisLine={false}
            tickMargin={0}
          />
          <YAxis type="category" dataKey="name" hide />

          <Bar
            dataKey="turnover"
            background={{
              fill: "#e5e5e5",
              radius: [10, 10, 10, 10],
            }}
            barSize={10}
            radius={[10, 10, 10, 10]}
          >
            <Cell fill={getBarColor(branchTurnover)} />
          </Bar>

          <ReferenceLine
            x={clampedPeerTurnover}
            stroke="#171717"
            strokeWidth={4}
            label={{
              value: `Peer: ${peerTurnover}%`,
              position:
                clampedPeerTurnover <= 10
                  ? "right"
                  : clampedPeerTurnover >= 90
                  ? "left"
                  : "bottom",
              fill: getTextColor(clampedPeerTurnover),
              fontSize: 10,
              fontWeight: "bold",
              dy:
                clampedPeerTurnover <= 10 || clampedPeerTurnover >= 90 ? 18 : 0,
            }}
          />

          <ReferenceLine
            x={clampedCompanyTurnover}
            stroke="#171717"
            strokeWidth={4}
            label={{
              value: `Company: ${companyTurnover}%`,
              position:
                clampedCompanyTurnover <= 10
                  ? "right"
                  : clampedCompanyTurnover >= 90
                  ? "left"
                  : "top",
              fill: getTextColor(clampedCompanyTurnover),
              fontSize: 10,
              fontWeight: "bold",
              dy:
                clampedCompanyTurnover <= 10 || clampedCompanyTurnover >= 90
                  ? -18
                  : 0,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TurnoverChart;
