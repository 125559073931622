import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Box, Snackbar, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { TextField } from "subframe";
import { Button } from "subframe/components/Button";

import AgapeCareLogo from "../../assets/agape-care-group/images/agape_care_logo.jpeg";
import ComfortKeepersLogo from "../../assets/comfort-keepers/images/comfort_keepers_logo.jpg";
import HelpingHandsLogo from "../../assets/helping-hands/images/helping_hands_logo.jpg";
import LongevityCareLogo from "../../assets/longevity-care/images/longevity_care_logo.png";
import RoyalHomeCareLogo from "../../assets/royal-home-care/images/royal_home_care_logo.png";
import StCroixLogo from "../../assets/st-croix/images/st_croix_logo.jpg";

import { addNewCaregiver } from "../../services/referral";

import { isEmpty as isStringEmpty } from "../../../../utils/string";

export default function Referral() {
  const location = useLocation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [agencyLogo, setAgencyLogo] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [agencyIdentifier, setAgencyIdentifier] = useState("");
  const [agencyRedirectLink, setAgencyRedirectLink] = useState("");
  const [referrerMobileNumber, setReferrerMobileNumber] = useState(
    searchParams.get("referrerMobileNumber") || ""
  );
  const [referrerFirstName, setReferrerFirstName] = useState(
    searchParams.get("referrerFirstName") || ""
  );
  const [referrerLastName, setReferrerLastName] = useState(
    searchParams.get("referrerLastName") || ""
  );
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [hasTriedFormSubmission, setHasTriedFormSubmission] = useState(false);

  useEffect(() => {
    setAgency();
  }, []);

  const setAgency = () => {
    if (location?.pathname === "/referral/agape-care-group") {
      setAgencyName("Agape Care Group");
      setAgencyLogo(AgapeCareLogo);
      setAgencyRedirectLink("https://careers.agapecaregroup.com/jobs");
      setAgencyIdentifier("agape-care-group");
    }

    if (location?.pathname === "/referral/royal-home-care") {
      setAgencyName("Royal Home Care");
      setAgencyLogo(RoyalHomeCareLogo);
      setAgencyIdentifier("royal-home-care");
    }

    if (location?.pathname === "/referral/longevity-care") {
      setAgencyName("Longevity Care");
      setAgencyLogo(LongevityCareLogo);
      setAgencyIdentifier("longevity-care");
    }

    if (location?.pathname === "/referral/comfort-keepers") {
      setAgencyName("Comfort Keepers");
      setAgencyLogo(ComfortKeepersLogo);
      setAgencyRedirectLink("https://www.comfortkeepers.jobs/jobs/");
      setAgencyIdentifier("comfort-keepers");
    }

    if (location?.pathname === "/referral/st-croix") {
      setAgencyName("St.Croix");
      setAgencyLogo(StCroixLogo);
      setAgencyIdentifier("st-croix");
    }

    if (location?.pathname === "/referral/helping-hands") {
      setAgencyName("Helping Hands");
      setAgencyLogo(HelpingHandsLogo);
      setAgencyIdentifier("helping-hands");
    }
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the success snackbar.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  const isFormValid =
    !isStringEmpty(firstName) &&
    !isStringEmpty(lastName) &&
    !isStringEmpty(mobileNumber);

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setMobileNumber("");
    setHasTriedFormSubmission(false);
  };

  /**
   * Submits the referral form.
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  const submitReferralFormAsync = async (payload) => {
    try {
      return await addNewCaregiver(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  /**
   * Handles the submission of the referral form.
   */
  const handleFormSubmit = async () => {
    setHasTriedFormSubmission(true);

    if (!isFormValid) {
      return;
    }

    setIsSubmitting(true);

    const response = await submitReferralFormAsync({
      first_name: firstName,
      last_name: lastName,
      phone_number: mobileNumber,
      referral_phone_number: referrerMobileNumber,
      agency_name: agencyIdentifier,
    });

    setIsSubmitting(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while submitting form: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    resetForm();
    setSuccessMessage("Successfully added.");
    setIsSuccessMessageShown(true);

    if (!isStringEmpty(agencyRedirectLink)) {
      navigate(`../intermediate-redirector?redirectUrl=${agencyRedirectLink}`);
    }
  };

  return (
    <>
      <div className="main-outer-wrapper">
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              gap: "12px",
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={agencyLogo}
                alt="Levo"
                style={{
                  width: "200px",
                }}
              />
            </Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "var(--fontWeight-bold)",
              }}
              gutterBottom
              className="text-default-font"
            >
              Welcome to {agencyName}.
            </Typography>

            <Box sx={{ gap: "8px", display: "flex", flexDirection: "column" }}>
              <Typography
                className="text-body-bold font-body-bold text-default-font"
                gutterBottom
              >
                Let's get you onto the team!
              </Typography>
              <Typography
                variant="body1"
                className="text-label-bold font-label-bold text-subtext-color"
              >
                Your friend
                {isStringEmpty(referrerFirstName) ? (
                  ""
                ) : (
                  <span className="text-brand-700">
                    , {referrerFirstName} {referrerLastName},
                  </span>
                )}{" "}
                will be your guide during this application process.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
              <TextField
                id="first-name"
                value={firstName}
                className="h-auto w-full flex-none"
                label="First Name"
                onChange={(event) => setFirstName(event?.target?.value)}
                helpText={
                  hasTriedFormSubmission && isStringEmpty(firstName)
                    ? "First Name is required."
                    : ""
                }
                error={hasTriedFormSubmission && isStringEmpty(firstName)}
              >
                <TextField.Input placeholder="" />
              </TextField>

              <TextField
                id="last-name"
                value={lastName}
                className="h-auto w-full flex-none"
                label="Last Name"
                onChange={(event) => setLastName(event?.target?.value)}
                helpText={
                  hasTriedFormSubmission && isStringEmpty(lastName)
                    ? "Last Name is required."
                    : ""
                }
                error={hasTriedFormSubmission && isStringEmpty(lastName)}
              >
                <TextField.Input placeholder="" />
              </TextField>

              <TextField
                type="tel"
                id="mobile-number"
                variant="outlined"
                value={mobileNumber}
                label="Mobile Number"
                placeholder="Enter Mobile Number"
                onChange={(event) => setMobileNumber(event?.target?.value)}
                helpText={
                  hasTriedFormSubmission && isStringEmpty(mobileNumber)
                    ? "Mobile Number is required."
                    : ""
                }
                error={hasTriedFormSubmission && isStringEmpty(mobileNumber)}
              >
                <TextField.Input placeholder="" />
              </TextField>

              <TextField
                disabled
                type="tel"
                id="referrer-mobile-number"
                variant="outlined"
                value={referrerMobileNumber}
                label="Referrer's Mobile Number"
                placeholder="Enter Referrer's Mobile Number"
                onChange={(event) =>
                  setReferrerMobileNumber(event?.target?.value)
                }
              >
                <TextField.Input placeholder={referrerMobileNumber} />
              </TextField>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "0 0 16px",
          }}
        >
          <Button
            className="h-10 w-full flex-none"
            variant="brand-primary"
            size="large"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleFormSubmit}
          >
            Let's get started!
          </Button>
        </Box>
      </div>
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
}
